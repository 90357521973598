import React, { memo, useState, useEffect, useCallback } from 'react';
import { parse } from 'query-string';
import { Col, Row } from 'antd';
import { graphql, Link } from 'gatsby';

import { InternalContainer } from './../basic-styles';
import { translators } from './page-data-translators';

import PublicLayout from '../../layout/public-layout';
import ClientSearch from '../../components/client-search';
import PageHeader from '../../components/common/page-header';
import { useI18NContext } from '../../i18n';

// The query used to provide the page data
export const query = graphql`
  query($language: String) {
    # Data for Page
    searchType: directusSearchType {
      name
      slug
      translation(language: $language) {
        value
      }
    }

    search: directusSearch {
      id
      translation(language: $language) {
        title
      }
    }

    # Data for Search engine:

    financialType: allDirectusFinancialsType(filter: { page: { id: { ne: null } } }) {
      items: nodes {
        id
        slug
        translation(language: $language) {
          body: value
        }
        page {
          translation(language: $language) {
            title: value
          }
        }
      }
    }

    finacialReportText: allDirectusFinancialsReport {
      items: nodes {
        id
        translation(language: $language) {
          body: text
        }
        type {
          translation(language: $language) {
            title: value
          }
          slug
        }
      }
    }

    finacialReportFiles: allDirectusFinancialsReport {
      items: nodes {
        type {
          translation(language: $language) {
            title: value
          }
          slug
        }
        reports {
          url
          year
          name
        }
      }
    }
    # end financials data

    collegePreps: allDirectusCollegePrep {
      items: nodes {
        id
        type: college_prep_type {
          slug
        }
        translation(language: $language) {
          body
          title
        }
      }
    }

    collegePrepSection: allDirectusCollegePrepSection {
      items: nodes {
        id
        translation(language: $language) {
          title: college_prep_section_value
          body: collge_prep_section_description
        }
        slug
      }
    }

    collegePrepType: allDirectusCollegePrepType {
      items: nodes {
        id
        slug
        translation(language: $language) {
          title: college_prep_type_value
        }
      }
    }
    # end college prep

    helpType: allDirectusHelpCenterType(filter: { page: { id: { ne: null } } }) {
      items: nodes {
        id
        slug
        translation(language: $language) {
          body: value
        }
        page {
          translation(language: $language) {
            title: value
          }
        }
      }
    }

    helpSections: allDirectusHelpCenterSection {
      items: nodes {
        id
        slug
        translation(language: $language) {
          body: value
        }
        type {
          page {
            translation(language: $language) {
              title: value
            }
          }
        }
      }
    }

    helpQA: allDirectusHelpCenterQa {
      items: nodes {
        id
        translation(language: $language) {
          title: question
          body: answer
        }
        section {
          slug
        }
        type {
          slug
        }
      }
    }

    # end help center

    advisorySections: allDirectusAdvisoryCouncilsSection {
      items: nodes {
        id
        slug
        translation(language: $language) {
          title: value
          body: description
        }
      }
    }

    # end advisory

    articlesList: allDirectusArticle {
      items: nodes {
        id
        translation(language: $language) {
          title: article_title
          body: article_summary
        }
        type: article_type {
          slug: article_type_slug
        }
      }
    }

    articlesDetail: allDirectusArticle {
      items: nodes {
        articleId: id
        slug: article_slug
        translation(language: $language) {
          title: article_title
          body: article_body
        }
      }
    }

    articleType: allDirectusArticleType {
      items: nodes {
        id
        translation(language: $language) {
          title: article_type_value
        }
        slug: article_type_slug
      }
    }

    # end articles

    teamsType: allDirectusTeamMemberType(filter: { page: { id: { ne: null } } }) {
      items: nodes {
        id
        slug: team_type_slug
        translation(language: $language) {
          body: team_type_value
        }
        page {
          translation(language: $language) {
            title: team_type_value
          }
        }
      }
    }

    teamsTypeDetail: allDirectusTeamMemberType(
      filter: { team_type_description: { ne: null }, page: { id: { ne: null } } }
    ) {
      items: nodes {
        teamTypeId: id
        name: team_type_name
        slug: team_type_slug
        translation(language: $language) {
          title: team_type_value
          body: team_type_description
        }
      }
    }

    teamMembers: allDirectusTeamMember {
      items: nodes {
        id
        slug
        bio: team_bio
        name: team_first_name
        surname: team_last_name
        translation(language: $language) {
          job: team_job_title
        }
        company
      }
    }

    # end team

    testimonialPageSections: allDirectusTestimonialSection {
      items: nodes {
        id
        slug: testimonial_slug
        translation(language: $language) {
          body: testimonial_section_value
        }
      }
    }

    testimonialSections: allDirectusTestimonialSection {
      items: nodes {
        sectionId: id
        name: testimonial_section_name
        slug: testimonial_slug
        translation(language: $language) {
          title: testimonial_section_value
          body: testimonial_section_description
        }
      }
    }

    testimonialDetails: allDirectusTestimonial {
      items: nodes {
        id
        translation(language: $language) {
          title: testimonial_title
          body: testimonial_body
        }
        section: testimonial_section {
          sectionId: id
          slug: testimonial_slug
        }
      }
    }

    # end testimonials

    hsfCareers: allDirectusHsfCareer {
      items: nodes {
        id
        type {
          slug
          translation(language: $language) {
            title: value
          }
        }
        translation(language: $language) {
          hsf_careers_description
          hsf_positions_section_title
        }
      }
    }

    hsfCareersPositions: allDirectusHsfCareersPosition {
      items: nodes {
        positionId: id
        type {
          slug
        }
        translation(language: $language) {
          title: position_title
          subtitle: position_department
          body: position_description
        }
      }
    }

    # hsf careers

    basicPages: allDirectusBasicPage {
      items: nodes {
        id
        slug: basic_page_slug
        translation(language: $language) {
          title: basic_page_title
          body: basic_page_content
        }
      }
    }

    # end basic

    landingTypes: allDirectusHsfLandingType {
      items: nodes {
        id
        slug
        translation(language: $language) {
          title: value
        }
      }
    }

    hasfLanding: allDirectusHsfLanding(filter: { page: { id: { ne: null } } }) {
      items: nodes {
        id
        type: page {
          slug
        }
        translation(language: $language) {
          title: hsf_bottom_section_title
          body: hsf_bottom_section_description
        }
      }
    }

    landingSections: allDirectusHsfLandingSection {
      items: nodes {
        id
        type: page {
          slug
        }
        translation(language: $language) {
          title: landing_section_title
          body: landing_section_body
        }
      }
    }

    # end Universities

    scholarshipType: allDirectusScholarshipType {
      items: nodes {
        id
        slug
        translation(language: $language) {
          title: value
        }
      }
    }

    scholarshipAbout: allDirectusHsfScholarshipMain {
      items: nodes {
        id
        type: page {
          slug
        }
        translation(language: $language) {
          status: scholarship_status_label
          apply_cta
          signin_cta
          title: about_section_title
          body: about_section_body
          sponsor: sponsor_section_title
        }
      }
    }

    scholarshipSections: allDirectusHsfScholarshipSection {
      items: nodes {
        id
        type: page {
          slug
        }
        translation(language: $language) {
          title: section_title
          body: section_body
        }
      }
    }

    scholarshipSponsors: allDirectusScholarshipSponsor {
      items: nodes {
        id
        translation(language: $language) {
          title: scholarship_sponsor_type
        }
        type: page {
          slug
        }
      }
    }

    # end scholarShip

    homeHero: allDirectusHomePageSection(filter: { home_section_type: { section_type_name: { eq: "hero" } } }) {
      items: nodes {
        homeHeroId: id
        translation(language: $language) {
          title: home_section_tagline
          body: home_section_description
          data: home_section_cta_text
        }
      }
    }

    homeSections: allDirectusHomePageSection(
      filter: { home_section_type: { section_type_name: { nin: ["hero", "additional", "card"] } } }
    ) {
      items: nodes {
        homeSectionsId: id
        translation(language: $language) {
          title2: home_section_tagline
          body: home_section_description
          title: home_section_title
          data: home_section_cta_text
        }
      }
    }

    homeAdditional: allDirectusHomePageSection(
      filter: { home_section_type: { section_type_name: { in: ["additional", "card"] } } }
    ) {
      items: nodes {
        homeAdditionalId: id
        translation(language: $language) {
          title: home_section_tagline
          title2: home_section_title
          body: home_section_description
          data: home_section_cta_text
        }
      }
    }

    homePartner: directusPartner(technology_partner: { eq: true }) {
      id
      # translation(language: $language) {
      title: partner_title
      body: partner_cta_label
      # }
    }

    homeFeaturesEvent: allDirectusFeaturedEvent {
      items: nodes {
        id
        translation(language: $language) {
          title: featured_events_title
          body: featured_events_description
          registerBtn: featured_events_register_button_label
          learnBtn: featured_events_learn_more_button_label
        }
      }
    }

    homefeaturedEventsBottomBanner: allDirectusFeaturedEventsBottomBanner {
      items: nodes {
        id
        translation(language: $language) {
          body: featured_events_bottom_description
          title: featured_events_bottom_title
          button: cta_label
        }
      }
    }

    # end index

    hsfStories: allDirectusHsfstoriesType {
      items: nodes {
        id
        translation(language: $language) {
          title: hsfstories_type_value
          body: hsfstories_tile
        }
        slug
      }
    }

    # hsf stories

    eventPage: allDirectusEventPage {
      items: nodes {
        id
        translation(language: $language) {
          title
          body
        }
        type {
          slug
        }
      }
    }

    eventsTypes: allDirectusEventsType {
      items: nodes {
        directusId
        translation(language: $language) {
          title: value
        }
      }
    }

    eventPageType: allDirectusEventPageType {
      items: nodes {
        id
        translation(language: $language) {
          body: value
        }
        slug
        page
      }
    }

    events: allDirectusEvent {
      items: nodes {
        id
        type: page {
          slug
        }
        translation(language: $language) {
          title: name
          body: description
        }
        location
      }
    }

    # end events

    eventCalendar: allDirectusEventCalendarType {
      items: nodes {
        id
        translation(language: $language) {
          tabTitle: event_calendar_type_value
          title
          body: description
        }
        slug
      }
    }

    # event calendar

    # end of Query
  }
`;

const ClientSearchTemplate = memo(function ClientSearchTemplate({
  data,
  pageContext: { options, language },
  location,
}: any) {
  const { translateSlug } = useI18NContext();
  const [pagesData, setPagesData] = useState<any[]>([]);
  const qs: any = location ? parse(location.search) : {};
  const query: string = qs && qs.query;

  const transfromDataForSearch = useCallback(() => {
    const result: any = [];
    console.log(data);
    translators.forEach(t => {
      t(data, result, translateSlug);
    });
    return result;
  }, [data, translateSlug]);

  useEffect(() => {
    setPagesData(transfromDataForSearch());
  }, [language, data, transfromDataForSearch]);

  return (
    <PublicLayout seoTitle={'some title'}>
      <InternalContainer>
        <Row justify="space-between">
          <Col xs={24}>
            <PageHeader
              title=""
              breadcrumb={[
                <Link key="1" to={translateSlug('/')}>
                  Home
                </Link>,
                data?.searchType?.translation?.value,
              ]}
              fullWidth
              isResponsive
              noShadow
            />
          </Col>
        </Row>
        <ClientSearch title={data.search.translation.title} pages={pagesData} engine={options} query={query} />
      </InternalContainer>
    </PublicLayout>
  );
});

export default ClientSearchTemplate;
