import React, { memo, useState, useEffect, useCallback } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import * as JsSearch from 'js-search';
import { Row, Col } from 'antd';

import { Link18 } from './common/link';

import { ArticleWrapper, Label, Summary, Header } from '../templates/basic-styles';
import { StyledPagination } from '../containers/college-finder/styles';
import { PageChangeEvent, Pagination } from '../components/table/pagination';

/* eslint-disable @typescript-eslint/no-var-requires */
const { htmlToText } = require('html-to-text');

type Props = {
  title: string;
  pages: any;
  engine: any;
  query: string;
};

const ClientSearch = memo(function ClientSearch({
  pages: initPages,
  engine: initEngine,
  query: initQuery,
  title,
}: Props) {
  const [queryResults, setQueryResults] = useState<any[]>(initPages);
  const [searchQuery, setSearchQuery] = useState<string>(initQuery);
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;
  const [engineSetup /*setEngine*/] = useState<any>({
    termFrequency: initEngine.SearchByTerm,
    selectedSanitizer: initEngine.searchSanitizer,
    selectedStrategy: initEngine.indexStrategy,
  });
  const [searchEngine, setSearchEngine] = useState<any>(null);
  const serchViaEngine = useCallback(
    value => {
      const queryResult = value && searchEngine ? searchEngine.search(value) : initPages;
      setQueryResults(queryResult);
    },
    [initPages, searchEngine]
  );

  useEffect(() => {
    setSearchQuery(initQuery);
  }, [initQuery]);

  //SetUp Search Engine
  const rebuildIndex = useCallback(() => {
    const { selectedStrategy, selectedSanitizer, removeStopWords, termFrequency } = engineSetup;

    const dataToSearch = new JsSearch.Search('id');

    removeStopWords && (dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(dataToSearch.tokenizer));
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === 'All') {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    }
    if (selectedStrategy === 'Exact match') {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy();
    }
    if (selectedStrategy === 'Prefix match') {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    }
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === 'Case Sensitive'
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer());
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('id'))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex());
    // sets the index attribute for the data

    dataToSearch.addIndex('title');

    dataToSearch.addIndex('body');

    dataToSearch.addDocuments(initPages); // adds the data to be searched

    setSearchEngine(dataToSearch);
  }, [initPages, engineSetup]);

  useEffect(() => {
    //console.log('INIT search engine...');
    rebuildIndex();
  }, [initPages, initEngine, rebuildIndex]);

  useEffect(() => {
    serchViaEngine(searchQuery ? searchQuery : '');
  }, [searchQuery, serchViaEngine]);

  const onPageChange = (event: PageChangeEvent) => {
    setCurrentPage(event.current);
    setOffset(event.offset);
  };

  const renderPagination = () => {
    return (
      <StyledPagination>
        <Pagination current={currentPage} total={queryResults.length} onChange={onPageChange} />
      </StyledPagination>
    );
  };

  ///UI
  return (
    <>
      {renderPagination()}
      <Row>
        <Col xs={24}>
          <Header>
            {title} &quot;{searchQuery}&quot;
          </Header>
        </Col>
      </Row>
      <div style={{ margin: '0 auto' }}>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        ></form>

        {queryResults &&
          queryResults
            ?.filter((_, index: any) => {
              return index < pageSize * currentPage && index >= offset;
            })
            .map((article, index) => {
              const title = article?.title?.replace(
                new RegExp(searchQuery, 'gi'),
                (match: any) => `<Mark style="background-color:yellow;padding:0">${match}</Mark>`
              );

              const text = htmlToText(article?.body, {
                wordwrap: 130,
              });
              const indexOfQuery = text.indexOf(searchQuery);
              const returnedWord = indexOfQuery > 20 ? indexOfQuery - 20 : 0;

              const getTruncatedText = (longText: string, maxCharCount: number) =>
                longText.length > maxCharCount
                  ? (returnedWord === 0 ? '' : '...') + longText.substr(returnedWord, maxCharCount - 1).trim() + '...'
                  : longText;

              const shorterText = getTruncatedText(text, 500);

              const body = shorterText.replace(
                new RegExp(searchQuery?.split(' ').join('|'), 'gi'),
                (match: any) => `<Mark style="background-color:yellow;padding:0">${match}</Mark>`
              );

              return (
                <ArticleWrapper key={index}>
                  <Row justify="start">
                    <Col xs={24}>
                      <Label>
                        <Link18 to={article.slug}>
                          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
                        </Link18>
                      </Label>
                    </Col>

                    <Col lg={24} md={24}>
                      <Summary hasImage={false}>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
                      </Summary>
                    </Col>
                  </Row>
                </ArticleWrapper>
              );
            })}
        {renderPagination()}
      </div>
    </>
  );
});
export default ClientSearch;
